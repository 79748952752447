@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 10px;
    height: 100%;
}

body {
    @apply antialiased min-h-full flex flex-col;

}
/*
#root {
    @apply flex flex-col flex-grow;
}

@font-face {
    font-family: 'AdihausDIN';
    src: url('./assets/fonts/AdihausDIN-Regular.woff2') format('woff2'),
    url('./assets/fonts/AdihausDIN-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AdihausDIN';
    src: url('./assets/fonts/AdihausDIN-CnBold.woff2') format('woff2'),
    url('./assets/fonts/AdihausDIN-CnBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
*/
