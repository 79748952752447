@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800&family=Roboto+Slab&display=swap");

h1 {
    @apply font-montserrat font-extrabold text-primary text-16;
}

h2 {
    @apply font-montserrat font-bold text-11;
}

h3 {
    @apply font-montserrat font-bold text-11 ;
}

p {
    @apply font-roboto text-12 text-gray-dark;
}

.line {
    @apply after:content-[""] after:block after:w-full after:h-px after:bg-black/10 after:my-4
}

.button {
    @apply uppercase font-montserrat font-bold text-10 rounded p-4 px-10 shadow-base;
}

.button--red {
    @apply bg-primary text-white;
}

.button--white {
    @apply bg-white text-primary;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.LogoBtn:hover {
    @apply bg-gray-dark/50;
}

.LogoBtn.active {
    box-shadow: 0 0 0 2px #941A1A;
}

.LogoBtn.active:hover {
    @apply bg-gray-light;
}

.PanoBtn:hover {
    box-shadow: 0 0 0 2px #941A1A;
}

.PanoBtn.active {
    box-shadow: 0 0 0 2px #941A1A;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

input:checked + div {
    @apply border-primary;
}

input:checked + div svg {
    @apply block;
}
